import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import { Facebook, FacebookMd, Twitter, TwitterMd, Instagram } from '../general/smicons';


export default function ContactCard(){

    const Query = useStaticQuery(graphql`
        query{
            datalogsJson {
          contact_page{
                    address_1 {
                        title
                        content
                      }
                      address_2 {
                        content
                        title
                      }
                      contacts
                      email
                    }
                  }
              }
    `);

    const data = Query.datalogsJson.contact_page

    return(
        <>
        <div className="flex flex-wrap text-white bg-gray-800 dark:bg-gray-600 container mx-auto px-10">
        <div className="md:w-5/12 w-full flex flex-wrap py-8">
            <div className="md:w-2/12 items-center flex mx-auto w-fit">
            <img src="/assets/images/location_icon.png" height={80} width={80}/>
            </div>
            <div className="flex flex-col md:w-5/12 md:py-0 lg:py-0 xl:px-0 2xl:py-0 py-10 px-6 w-full flex-col items-center mx-auto text-sm">
            <span className="text-sgreen">{data.address_1.title}</span>
            <div className="border-b-2 border-sgreen sm:block hidden w-3/12 sm:my-2" />
            <span  className="text-center">{data.address_1.content}</span>
            </div>
            <div className="flex flex-col md:w-5/12 w-full items-center px-6 mx-auto text-sm">
            <span className="text-sgreen">{data.address_2.title}</span>
            <div className="border-b-2 border-sgreen sm:block hidden w-3/12 sm:my-2" />
            <span className="text-center">{data.address_2.content}</span>
            </div>
        </div>
        {/*Second column*/}
        <div className="md:w-7/12 w-full flex flex-wrap py-10">
            <div className="md:w-4/12 items-center flex-col flex w-full content-around">
            <img src="/assets/images/phone_svg.png" className="mb-4" height={30} width={30}/>
            {data.contacts.map((body, index) =>{
              return (
              <a href={'tel:'+body}>{body}</a>
              )
            })}
            </div>
            <div className="flex flex-col md:w-4/12 md:py-0 lg:py-0 xl:px-0 2xl:py-0 pb-8 py-4 px-6 w-full flex-col items-center mx-auto text-sm">
            <img src="/assets/images/message_icon.png" className="mb-4" height={30} width={30}/>
            <span><a href={'mailto:'+data.email}>{data.email}</a></span>
            </div>
            <div className="flex flex-wrap md:w-4/12 w-full items-center justify-evenly text-sm">
            <a href="https://www.facebook.com/profile.php?id=100067916179666" target="_blank" > <FacebookMd /> </a>
            <a href="https://twitter.com/psaltryng?s=21&t=Wqm0Wx56yxzsxJTLui-v4g" target="_blank" > <TwitterMd /> </a>
            {/* <Instagram /> */}
            <a href="https://instagram.com/psaltry_international?igshid=YmMyMTA2M2Y=" target="_blank" ><img src="assets/images/insta_icon.png" alt="fb" height={28} width={28} /></a>
            <a href="https://www.linkedin.com/company/psaltry-international-company-ltd/" target="_blank" ><img src="assets/images/linkedIn.png" alt="fb" height={28} width={28} /></a>
            </div>
        </div>
        </div>
        </>
    )
}

