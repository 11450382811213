import React from "react";
import { SiteMeta } from "../general/seo";
import { TopBar, BottomBar } from "../general/extremeBars";
import NavBar from "../general/navbar";
import BaseCard from "./baseCard";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
  query {
    datalogsJson {
      contact_page {
        contact_content
        company_map {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export function ContactPage({ data }) {
  const mapImage =
    data.datalogsJson.contact_page.company_map.childImageSharp.gatsbyImageData;
  return (
    <>
      <SiteMeta
        title="Contact Us - Psaltry International Company Limited"
        description="Official Psaltry Company Information"
      />
      <div className="dark:bg-gray-800">
      <TopBar />
      <NavBar />
      <div className="flex flex-wrap">
        <div className="w-full mt-4 md:mt-10 md:mx-auto p-8 md:pl-12 md:w-1/2 md:block lg:block xl:block">
          {/* <GatsbyImage 
    image={mapImage}
    className="w-full h-full"
    /> */}

          <div
            role="heading"
            className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen"
          >
            Contact Us
          </div>
          <div className="border-b-4 border-sgreen w-2/12 mt-4 mb-6" />

          {data.datalogsJson.contact_page.contact_content.map((body, index) => {
            return (
              <>
                <p
                  role="contentinfo"
                  className="focus:outline-none text-base leading-6 mt-4 text-justify dark:text-white"
                >
                  {body}
                </p>
              </>
            );
          })}
        </div>
        <div className="md:w-1/2 sm:w-full flex items-center justify-center mx-auto">
          <div className="flex flex-col items-center py-10 px-10 bg-gray-50 rounded-lg border-t-2 boder-l-1 border-r-1 border-b-4 border-sgreen my-8 dark:bg-gray-500">
            <div className="dark:text-white">Send us a message</div>
            <div className="border-b-2 border-sgreen w-3/12 mb-4" />
            {/* form wrapper */}
            <div className="py-4">
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email1"
                  className="text-gray-800 dark:text-gray-100 text-sm leading-tight tracking-normal mb-2"
                >
                  Full name
                </label>
                <div className="relative">
                  <div className="absolute text-gray-600 flex items-center px-4 border-r dark:border-gray-700 h-full cursor-pointer">
                    <img
                      width={18}
                      height={18}
                      alt="user_icon"
                      src="https://img.icons8.com/ios-filled/50/000000/user.png"
                    />
                  </div>
                  <input
                    id="email2"
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-10 flex items-center pl-16 text-sm border-gray-300 rounded border"
                  />
                </div>
              </div>
              {/* Code block ends */}
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email2"
                  className="text-gray-800 dark:text-gray-100 text-sm leading-tight tracking-normal my-2"
                >
                  Email
                </label>
                <div className="relative">
                  <div className="absolute text-gray-600 flex items-center px-4 border-r dark:border-gray-700 h-full cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-mail"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <rect x={3} y={5} width={18} height={14} rx={2} />
                      <polyline points="3 7 12 13 21 7" />
                    </svg>
                  </div>
                  <input
                    id="email2"
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-10 flex items-center pl-16 text-sm border-gray-300 rounded border"
                  />
                </div>
              </div>
              {/* Code block ends */}
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email1"
                  className="text-gray-800 text-sm leading-tight tracking-normal my-2 dark:text-gray-100"
                >
                  Phone Number
                </label>
                <div className="relative">
                  <div className="absolute text-gray-600 flex items-center px-4 border-r dark:border-gray-700 h-full cursor-pointer">
                    <img
                      src="https://img.icons8.com/ios-glyphs/30/000000/phone--v1.png"
                      height={18}
                      width={18}
                    />
                  </div>
                  <input
                    id="email2"
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-10 flex items-center pl-16 text-sm border-gray-300 rounded border"
                  />
                </div>
              </div>
              {/* Code block ends */}
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email1"
                  className="text-gray-800 text-sm leading-tight tracking-normal my-2 dark:text-gray-100"
                >
                  Your message
                </label>
                <div className="relative">
                  <textarea
                    id="email1"
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-44 resize-none flex items-center text-sm border-gray-300 rounded border"
                  ></textarea>
                </div>
              </div>
              {/* Code block ends */}

              <button className="rounded-sm bg-sgreen tracking-normal text-white py-2 text-sm px-10 mt-6">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <BaseCard />
      </div>
      <BottomBar />
    </>
  );
}

export default ContactPage;
